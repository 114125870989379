/* Scroll to top
---------------------------------- */
.scrollToTop {
  color: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  background-color: rgba(120, 188, 188, 0.4);
  z-index: 1005;
  display: none;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.scrollToTop i {
  line-height: 40px;
  font-size: 21px;
}
.scrollToTop:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.stt-sq {
  border-top-right-radius: 10%;
  float:left;
}

.circle {
  border-radius: 50%;
  float:left;
}

.footer-big-menu {
  padding-bottom: 130px;
  background: #2B4255;
  position: relative;
  padding-top:1px;
  margin-top:0!important
}


.menu-item {
  color: #97b0c5;
  display: block;
  margin-bottom: 2px;
}

.agency-alt-contact-info {

  text-align: center;

  .col-md-4 {
    border-left: none;
  }
}

.agency-start-project-intro {
  margin-top: 40px;
}


.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-80 {
  margin-bottom: 80px;
}

.case-image {
  width: 100%;
  border-radius: 15px;
}

.pricing-charts-header {
  overflow:hidden;
}

.footer-big-menu {
  margin-top: 80px;
}

.social-icons {
  margin-top:30px !important;
}
.social-link-spaced {
  margin-left:10px;
  margin-right:10px;
}

.logo-box {
  height: 180px;

  img {
    max-width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.hidden-input {
  display:none;
}