/* Logo */

#silverark-logo {
  max-width: 200px;
  overflow: visible;

}
#logo-circle {
  stroke: #83b5b5;
}

#logo-arrow {
  fill: #ffffff;
}

#logo-dash {
  fill: #ffffff;
}

.logobox {
  width: 200px;
  height:55px;
  padding-top: 0px;
  margin-top:-3px;
}

.agency-intro h1 {
  text-align: center;
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  position: relative;
}

.pricing-charts-header section h1 {
  color: #fff;
  font-size: 29px;
  font-weight: 400;
  text-align: center;
}


/* Homepage */
.homepage-hero-module .video-container {
  background: linear-gradient(-45deg,rgba(120,188,180,.95),rgba(0,0,0,.65));
}

.navbar-dark {
  background-image: linear-gradient(90deg,#20345a 0,#476991 100%);
}

.navbar-black {
  background-image: linear-gradient(90deg,#20345a 0,#000000 100%);
}

.homepage-hero-module {

  .vimeo_player_overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(64, 76, 93, 0.65);
  }

  .vimeo_player_overlay {
    background: linear-gradient(0deg,rgba(0,0,0,.15),rgba(0,0,0,.65)); /* rgba(64, 76, 93, 0.65); */
  }

  .no-video .video-container video,
  .touch .video-container video {
    display: none;
  }

  .no-video .video-container .poster,
  .touch .video-container .poster {
    display: block !important;
  }

  .video-container {
    position: relative;
    bottom: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-image: url('/images/home/silverark-scroll-bg-image.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .video-container .poster img {
    width: 100%;
    bottom: 0;
    position: absolute;
  }

  .video-container .filter {
    z-index: 100;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
  }

  .video-container video {
    //position: absolute;
    //z-index: 0;
    //bottom: 0;

    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .video-container video.fillWidth {
    //width: 100%;
  }
}


.hidden {
  display:none;
}

#typed-strings {
  display:none;
}

.highlight {
  color: #a1ddde; //83b5b5
}

.agency-alt-hero:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(85,93,107,.7),rgba(85,93,107,.15));
}


.silverark-policy {

  .lst-kix_list_4-1 > li {
    counter-increment: lst-ctn-kix_list_4-1
  }

  ol.lst-kix_list_3-1 {
    list-style-type: none
  }

  ol.lst-kix_list_3-2 {
    list-style-type: none
  }

  .lst-kix_list_3-1 > li {
    counter-increment: lst-ctn-kix_list_3-1
  }

  ol.lst-kix_list_3-3 {
    list-style-type: none
  }

  ol.lst-kix_list_3-4.start {
    counter-reset: lst-ctn-kix_list_3-4 0
  }

  ol.lst-kix_list_3-4 {
    list-style-type: none
  }

  .lst-kix_list_2-1 > li {
    counter-increment: lst-ctn-kix_list_2-1
  }

  ol.lst-kix_list_3-0 {
    list-style-type: none
  }

  .lst-kix_list_1-1 > li {
    counter-increment: lst-ctn-kix_list_1-1
  }

  ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0
  }

  .lst-kix_list_3-0 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, decimal) ". "
  }

  ol.lst-kix_list_3-1.start {
    counter-reset: lst-ctn-kix_list_3-1 0
  }

  .lst-kix_list_3-1 > li:before {
    content: "" counter(lst-ctn-kix_list_3-1, decimal) ". "
  }

  .lst-kix_list_3-2 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, decimal) "." counter(lst-ctn-kix_list_3-2, decimal) " "
  }

  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0
  }

  .lst-kix_list_4-0 > li {
    counter-increment: lst-ctn-kix_list_4-0
  }

  ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0
  }

  .lst-kix_list_3-5 > li:before {
    content: "" counter(lst-ctn-kix_list_3-5, lower-latin) ") "
  }

  .lst-kix_list_3-4 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, decimal) "." counter(lst-ctn-kix_list_3-2, decimal) "." counter(lst-ctn-kix_list_3-4, decimal) " "
  }

  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0
  }

  .lst-kix_list_3-3 > li:before {
    content: "" counter(lst-ctn-kix_list_3-3, lower-latin) ") "
  }

  ol.lst-kix_list_3-5 {
    list-style-type: none
  }

  ol.lst-kix_list_3-6 {
    list-style-type: none
  }

  ol.lst-kix_list_3-7 {
    list-style-type: none
  }

  ol.lst-kix_list_3-8 {
    list-style-type: none
  }

  .lst-kix_list_3-8 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, decimal) "." counter(lst-ctn-kix_list_3-1, decimal) "." counter(lst-ctn-kix_list_3-2, decimal) "." counter(lst-ctn-kix_list_3-3, lower-latin) "." counter(lst-ctn-kix_list_3-4, decimal) "." counter(lst-ctn-kix_list_3-5, lower-latin) "." counter(lst-ctn-kix_list_3-6, decimal) "." counter(lst-ctn-kix_list_3-7, lower-latin) "." counter(lst-ctn-kix_list_3-8, decimal) ". "
  }

  .lst-kix_list_2-0 > li {
    counter-increment: lst-ctn-kix_list_2-0
  }

  .lst-kix_list_2-3 > li {
    counter-increment: lst-ctn-kix_list_2-3
  }

  .lst-kix_list_3-6 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, decimal) "." counter(lst-ctn-kix_list_3-2, decimal) "." counter(lst-ctn-kix_list_3-4, decimal) "." counter(lst-ctn-kix_list_3-6, decimal) " "
  }

  .lst-kix_list_4-3 > li {
    counter-increment: lst-ctn-kix_list_4-3
  }

  .lst-kix_list_3-7 > li:before {
    content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ") "
  }

  ol.lst-kix_list_4-5.start {
    counter-reset: lst-ctn-kix_list_4-5 0
  }

  .lst-kix_list_1-2 > li {
    counter-increment: lst-ctn-kix_list_1-2
  }

  ol.lst-kix_list_3-7.start {
    counter-reset: lst-ctn-kix_list_3-7 0
  }

  ol.lst-kix_list_4-2.start {
    counter-reset: lst-ctn-kix_list_4-2 0
  }

  .lst-kix_list_3-2 > li {
    counter-increment: lst-ctn-kix_list_3-2
  }

  ol.lst-kix_list_2-2 {
    list-style-type: none
  }

  ol.lst-kix_list_2-3 {
    list-style-type: none
  }

  ol.lst-kix_list_2-4 {
    list-style-type: none
  }

  ol.lst-kix_list_2-5 {
    list-style-type: none
  }

  .lst-kix_list_1-4 > li {
    counter-increment: lst-ctn-kix_list_1-4
  }

  .lst-kix_list_4-4 > li {
    counter-increment: lst-ctn-kix_list_4-4
  }

  ol.lst-kix_list_2-0 {
    list-style-type: none
  }

  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0
  }

  ol.lst-kix_list_2-1 {
    list-style-type: none
  }

  .lst-kix_list_4-8 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) "." counter(lst-ctn-kix_list_4-1, decimal) "." counter(lst-ctn-kix_list_4-2, decimal) "." counter(lst-ctn-kix_list_4-3, lower-latin) "." counter(lst-ctn-kix_list_4-4, decimal) "." counter(lst-ctn-kix_list_4-5, lower-latin) "." counter(lst-ctn-kix_list_4-6, decimal) "." counter(lst-ctn-kix_list_4-7, lower-latin) "." counter(lst-ctn-kix_list_4-8, decimal) ". "
  }

  .lst-kix_list_4-7 > li:before {
    content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ") "
  }

  ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 0
  }

  ol.lst-kix_list_4-8.start {
    counter-reset: lst-ctn-kix_list_4-8 0
  }

  ol.lst-kix_list_3-3.start {
    counter-reset: lst-ctn-kix_list_3-3 0
  }

  ol.lst-kix_list_2-6 {
    list-style-type: none
  }

  ol.lst-kix_list_2-7 {
    list-style-type: none
  }

  ol.lst-kix_list_2-8 {
    list-style-type: none
  }

  ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0
  }

  .lst-kix_list_3-0 > li {
    counter-increment: lst-ctn-kix_list_3-0
  }

  .lst-kix_list_3-3 > li {
    counter-increment: lst-ctn-kix_list_3-3
  }

  ol.lst-kix_list_4-0.start {
    counter-reset: lst-ctn-kix_list_4-0 0
  }

  .lst-kix_list_3-6 > li {
    counter-increment: lst-ctn-kix_list_3-6
  }

  .lst-kix_list_2-5 > li {
    counter-increment: lst-ctn-kix_list_2-5
  }

  .lst-kix_list_2-8 > li {
    counter-increment: lst-ctn-kix_list_2-8
  }

  ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 0
  }

  .lst-kix_list_2-2 > li {
    counter-increment: lst-ctn-kix_list_2-2
  }

  ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0
  }

  ol.lst-kix_list_4-7.start {
    counter-reset: lst-ctn-kix_list_4-7 0
  }

  ol.lst-kix_list_1-3 {
    list-style-type: none
  }

  ol.lst-kix_list_1-4 {
    list-style-type: none
  }

  .lst-kix_list_2-6 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) "." counter(lst-ctn-kix_list_2-4, decimal) "." counter(lst-ctn-kix_list_2-6, decimal) " "
  }

  .lst-kix_list_2-7 > li:before {
    content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ") "
  }

  .lst-kix_list_2-7 > li {
    counter-increment: lst-ctn-kix_list_2-7
  }

  .lst-kix_list_3-7 > li {
    counter-increment: lst-ctn-kix_list_3-7
  }

  ol.lst-kix_list_1-5 {
    list-style-type: none
  }

  ol.lst-kix_list_1-6 {
    list-style-type: none
  }

  ol.lst-kix_list_1-0 {
    list-style-type: none
  }

  .lst-kix_list_2-4 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) "." counter(lst-ctn-kix_list_2-4, decimal) " "
  }

  .lst-kix_list_2-5 > li:before {
    content: "" counter(lst-ctn-kix_list_2-5, lower-latin) ") "
  }

  .lst-kix_list_2-8 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) "." counter(lst-ctn-kix_list_2-3, lower-latin) "." counter(lst-ctn-kix_list_2-4, decimal) "." counter(lst-ctn-kix_list_2-5, lower-latin) "." counter(lst-ctn-kix_list_2-6, decimal) "." counter(lst-ctn-kix_list_2-7, lower-latin) "." counter(lst-ctn-kix_list_2-8, decimal) ". "
  }

  ol.lst-kix_list_1-1 {
    list-style-type: none
  }

  ol.lst-kix_list_1-2 {
    list-style-type: none
  }

  ol.lst-kix_list_4-6.start {
    counter-reset: lst-ctn-kix_list_4-6 0
  }

  ol.lst-kix_list_3-0.start {
    counter-reset: lst-ctn-kix_list_3-0 0
  }

  ol.lst-kix_list_4-3.start {
    counter-reset: lst-ctn-kix_list_4-3 0
  }

  ol.lst-kix_list_1-7 {
    list-style-type: none
  }

  .lst-kix_list_4-7 > li {
    counter-increment: lst-ctn-kix_list_4-7
  }

  .lst-kix_list_1-7 > li {
    counter-increment: lst-ctn-kix_list_1-7
  }

  ol.lst-kix_list_1-8 {
    list-style-type: none
  }

  ol.lst-kix_list_3-8.start {
    counter-reset: lst-ctn-kix_list_3-8 0
  }

  ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0
  }

  .lst-kix_list_4-0 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) ". "
  }

  .lst-kix_list_2-6 > li {
    counter-increment: lst-ctn-kix_list_2-6
  }

  .lst-kix_list_3-8 > li {
    counter-increment: lst-ctn-kix_list_3-8
  }

  .lst-kix_list_4-1 > li:before {
    content: "" counter(lst-ctn-kix_list_4-1, decimal) ". "
  }

  .lst-kix_list_4-6 > li {
    counter-increment: lst-ctn-kix_list_4-6
  }

  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0
  }

  .lst-kix_list_4-4 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) "." counter(lst-ctn-kix_list_4-2, decimal) "." counter(lst-ctn-kix_list_4-4, decimal) " "
  }

  ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0
  }

  .lst-kix_list_1-5 > li {
    counter-increment: lst-ctn-kix_list_1-5
  }

  .lst-kix_list_4-3 > li:before {
    content: "" counter(lst-ctn-kix_list_4-3, lower-latin) ") "
  }

  .lst-kix_list_4-5 > li:before {
    content: "" counter(lst-ctn-kix_list_4-5, lower-latin) ") "
  }

  .lst-kix_list_4-2 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) "." counter(lst-ctn-kix_list_4-2, decimal) " "
  }

  .lst-kix_list_4-6 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) "." counter(lst-ctn-kix_list_4-2, decimal) "." counter(lst-ctn-kix_list_4-4, decimal) "." counter(lst-ctn-kix_list_4-6, decimal) " "
  }

  .lst-kix_list_1-8 > li {
    counter-increment: lst-ctn-kix_list_1-8
  }

  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0
  }

  .lst-kix_list_3-5 > li {
    counter-increment: lst-ctn-kix_list_3-5
  }

  ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0
  }

  ol.lst-kix_list_4-0 {
    list-style-type: none
  }

  .lst-kix_list_3-4 > li {
    counter-increment: lst-ctn-kix_list_3-4
  }

  ol.lst-kix_list_4-1 {
    list-style-type: none
  }

  ol.lst-kix_list_4-4.start {
    counter-reset: lst-ctn-kix_list_4-4 0
  }

  ol.lst-kix_list_4-2 {
    list-style-type: none
  }

  ol.lst-kix_list_4-3 {
    list-style-type: none
  }

  .lst-kix_list_2-4 > li {
    counter-increment: lst-ctn-kix_list_2-4
  }

  ol.lst-kix_list_3-6.start {
    counter-reset: lst-ctn-kix_list_3-6 0
  }

  ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0
  }

  ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0
  }

  ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0
  }

  ol.lst-kix_list_4-8 {
    list-style-type: none
  }

  .lst-kix_list_1-0 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) ". "
  }

  ol.lst-kix_list_4-4 {
    list-style-type: none
  }

  ol.lst-kix_list_4-5 {
    list-style-type: none
  }

  .lst-kix_list_1-1 > li:before {
    content: "" counter(lst-ctn-kix_list_1-1, decimal) ". "
  }

  .lst-kix_list_1-2 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) " "
  }

  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0
  }

  ol.lst-kix_list_4-6 {
    list-style-type: none
  }

  ol.lst-kix_list_4-7 {
    list-style-type: none
  }

  .lst-kix_list_1-3 > li:before {
    content: "" counter(lst-ctn-kix_list_1-3, lower-latin) ") "
  }

  .lst-kix_list_1-4 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) "." counter(lst-ctn-kix_list_1-4, decimal) " "
  }

  ol.lst-kix_list_3-5.start {
    counter-reset: lst-ctn-kix_list_3-5 0
  }

  .lst-kix_list_1-0 > li {
    counter-increment: lst-ctn-kix_list_1-0
  }

  .lst-kix_list_4-8 > li {
    counter-increment: lst-ctn-kix_list_4-8
  }

  .lst-kix_list_1-6 > li {
    counter-increment: lst-ctn-kix_list_1-6
  }

  .lst-kix_list_1-7 > li:before {
    content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ") "
  }

  ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0
  }

  .lst-kix_list_1-3 > li {
    counter-increment: lst-ctn-kix_list_1-3
  }

  .lst-kix_list_1-5 > li:before {
    content: "" counter(lst-ctn-kix_list_1-5, lower-latin) ") "
  }

  .lst-kix_list_1-6 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) "." counter(lst-ctn-kix_list_1-4, decimal) "." counter(lst-ctn-kix_list_1-6, decimal) " "
  }

  .lst-kix_list_2-0 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) ". "
  }

  .lst-kix_list_2-1 > li:before {
    content: "" counter(lst-ctn-kix_list_2-1, decimal) ". "
  }

  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0
  }

  .lst-kix_list_4-5 > li {
    counter-increment: lst-ctn-kix_list_4-5
  }

  .lst-kix_list_1-8 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) "." counter(lst-ctn-kix_list_1-3, lower-latin) "." counter(lst-ctn-kix_list_1-4, decimal) "." counter(lst-ctn-kix_list_1-5, lower-latin) "." counter(lst-ctn-kix_list_1-6, decimal) "." counter(lst-ctn-kix_list_1-7, lower-latin) "." counter(lst-ctn-kix_list_1-8, decimal) ". "
  }

  .lst-kix_list_2-2 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) " "
  }

  .lst-kix_list_2-3 > li:before {
    content: "" counter(lst-ctn-kix_list_2-3, lower-latin) ") "
  }

  .lst-kix_list_4-2 > li {
    counter-increment: lst-ctn-kix_list_4-2
  }

  ol {
    margin: 0;
    padding: 0
  }

  table td, table th {
    padding: 0
  }

  .c9 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 13pt!important;
  }

  .c14 {
    margin-left: 106.3pt;
    padding-top: 0pt;
    text-indent: -35.4pt;
    padding-bottom: 6pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 11pt
  }

  .c15 {
    margin-left: 18pt;
    padding-top: 6pt;
    padding-left: 17.5pt;
    padding-bottom: 6pt;
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c16 {
    margin-left: 88.9pt;
    padding-top: 0pt;
    padding-left: 17.4pt;
    padding-bottom: 6pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c37 {
    background-color: #ffffff;
    color: #808080;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9.5pt;
    font-style: normal
  }

  .c12 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal
  }

  .c21 {
    -webkit-text-decoration-skip: none;
    font-weight: 700;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 12pt;
  }

  .c5 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal;
    display: table;
    margin-top:10px;
  }

  .c5 li {
    display: table-row;
  }

  .c5 li::before {
    display: table-cell;
    padding-right: 10px;
  }

  .c8::before {
    display: table-cell;
    padding-right: 10px;
    padding-left:30px;
  }


  .c5.lst-kix_list_1-3 .c2::before {
    display: table-cell;
    padding-right: 10px;
    padding-left:30px;
  }

  .c19 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 12pt;
    font-style: normal
  }

  .c1 {
    color: #000000;

    text-decoration: none;
    vertical-align: baseline;

    font-style: normal
  }

  .c13 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 8pt;
    font-style: normal
  }

  .c8 {
    -webkit-text-decoration-skip: none;
    text-decoration: none;
    text-decoration-skip-ink: none;
  }

  .p-space {
    padding-bottom:10px;
    font-weight:300;
  }

  .c5 .c8 {
    font-weight:300;
  }
  .c28 {
    margin-left: 18pt;
    padding-top: 0pt;
    padding-left: 17.5pt;
    padding-bottom: 6pt;
    line-height: 1.0;
    text-align: justify
  }

  .c34 {
    margin-left: 18pt;
    padding-top: 0pt;
    padding-left: 17.5pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
  }

  .c0 {
    margin-left: 0;
    padding-top: 0pt;
    padding-left: 0;
    padding-bottom: 6pt;
    line-height: 1.0;
    text-align: justify
  }

  .c3 {
    padding-top: 0pt;
    padding-bottom: 6pt;
    line-height: 1.0;
    text-indent: -35.5pt;
    text-align: justify;
    height: 11pt
  }

  .c17 {
    padding-top: 0pt;
    padding-bottom: 10pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c30 {
    margin-left: 36pt;
    padding-top: 0pt;
    text-indent: -0.5pt;
    padding-bottom: 6pt;
    line-height: 1.0;
    text-align: justify
  }

  .c11 {
    margin-left: 35.5pt;
    padding-top: 0pt;
    text-indent: -35.5pt;
    padding-bottom: 6pt;
    line-height: 1.0;
    text-align: justify
  }

  .c20 {
    margin-left: 36pt;
    padding-top: 0pt;
    text-indent: -35.5pt;
    padding-bottom: 6pt;
    line-height: 1.0;
    text-align: justify
  }

  .c29 {
    padding-top: 6pt;
    text-indent: -35.5pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    height: 11pt
  }

  .c22 {
    padding-top: 0pt;
    padding-bottom: 6pt;
    line-height: 1.0;
    text-indent: -35.5pt;
    text-align: justify
  }

  .c6 {
    padding-top: 0pt;
    text-indent: -35.5pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
  }

  .c18 {
    padding-top: 0pt;
    padding-bottom: 6pt;
    line-height: 1.0;
    text-align: justify;
    height: 11pt
  }

  .c10 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    height: 11pt
  }

  .c38 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
  }

  .c25 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal
  }

  .c4 {
    margin-left: 35.5pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2
  }

  .c36 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
  }

  .c40 {
    background-color: #ffffff;
    max-width: 453.6pt;
    padding: 70.9pt 70.9pt 70.9pt 70.9pt
  }

  .c33 {
    page-break-after: avoid;
    orphans: 2;
    widows: 2
  }

  .c26 {

    color: #ffc000;
    font-weight: 700
  }

  .c7 {
    padding: 0;
    margin: 0
  }

  .c35 {
    vertical-align: baseline;
    font-size: 8pt
  }

  .c31 {
    font-weight: 700;

  }

  .c2 {
    font-weight: 300;
    font-size:15px;

  }

  .c23 {
    color: inherit;
    text-decoration: inherit
  }

  .c39 {
    margin-left: 36pt
  }

  .c32 {
    color: #ffc000
  }

  .c24 {
    margin-left: 70.9pt
  }

  .c27 {
    margin-left: 35.5pt
  }

  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 28pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;

    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    text-align: justify
  }

  li {
    color: #000000;
    font-size: 11pt;

  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;

  }

  h1 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 16pt;
    padding-bottom: 3pt;

    line-height: 1.0;
    page-break-after: avoid;
    text-align: justify
  }

  h2 {
    padding-top: 2pt;
    color: #2f5496;
    font-size: 13pt;
    padding-bottom: 0pt;

    line-height: 1.0;
    page-break-after: avoid;
    text-align: justify
  }

  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;

    line-height: 1.0;
    page-break-after: avoid;
    text-align: justify
  }

  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;

    line-height: 1.0;
    page-break-after: avoid;
    text-align: justify
  }

  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;

    line-height: 1.0;
    page-break-after: avoid;
    text-align: justify
  }

  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;

    line-height: 1.0;
    page-break-after: avoid;
    text-align: justify
  }
}

.footer-big-menu {
  margin-top: 80px;
}

.social-icons {
  margin-top:30px !important;
}
.social-link-spaced {
  margin-left:10px;
  margin-right:10px;
}

/* Articles Page */

.blog-cols-wrapper .post .post-wrapper{
  height:370px;
}

.blog-cols-wrapper .post .post-bg {
  height:205px;
}